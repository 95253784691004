module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Pom-Pom Lab","short_name":"Pom-Pom Lab","lang":"en","start_url":"/","background_color":"#0c0f1d","theme_color":"#0c0f1d","display":"standalone","icons":[{"src":"/android-chrome-192x192.png","sizes":"192x192"},{"src":"/maskable_icon_x128.png","sizes":"128x128","purpose":"maskable"}],"icon_options":{"type":"image/png"},"localize":[{"lang":"ja","start_url":"/ja/","name":"Pom-Pom Lab","short_name":"Pom-Pom Lab"},{"lang":"ko","start_url":"/ko/","name":"Pom-Pom Lab","short_name":"Pom-Pom Lab"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":null},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"languages":["en","ja","ko"],"defaultLanguage":"en","siteUrl":"https://starrail.luis.fun"},
    },{
      plugin: require('../node_modules/gatsby-theme-material-ui-top-layout/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-material-ui/gatsby-browser.js'),
      options: {"plugins":[],"pathToEmotionCacheProps":""},
    },{
      plugin: require('../gatsby-browser.tsx'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
